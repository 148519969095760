import picoStylesheetUrl from '@picocss/pico/css/pico.min.css';
import {
  type HeadersFunction,
  type LinksFunction,
  type LoaderArgs,
  type MetaFunction,
  type SerializeFrom } from
'@remix-run/node';
import {
  Link,
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useCatch,
  useLoaderData,
  useLocation } from
'@remix-run/react';
import { withSentry } from '@sentry/remix';
import tremorCss from '@tremor/react/dist/esm/tremor.css';
import { startsWith } from 'lodash-es';
import { lazy, Suspense, useEffect, useRef } from 'react';
// import { ToastContainer } from 'react-toastify'
// import toastStylesheetUrl from 'react-toastify/dist/ReactToastify.min.css'
import { createHead } from 'remix-island';

import { jsonHash, useEventSource, useHydrated } from 'remix-utils';
import { Toaster, toast } from 'sonner';
import { type RouteHandle } from '#types/routes.ts';
import {
  getPublicKeys,
  // getUser,
  logger,
  PublicEnv
  // getClientSession,
} from '~/utils/index.ts';
import FeedbackForm from './components/global/feedback-form.tsx';
// import DiscountCard from './components/global/discount-card.tsx'
import { Footer } from './components/global/footer.tsx';
import GlobalErrorBoundary from './components/global/global-error-boundary.tsx';
import { Navbar } from './components/global/navbar.tsx';
import { getUser } from './sessions/auth.server.ts';
import { getPlatformSession } from './sessions/platform.server.ts';
import { getToastSession } from './sessions/toast.server.ts';
import tailwindStylesheetUrl from './styles/tailwind.css';
import themeStylesheetUrl from './styles/theme.css';
import { getHints } from './utils/client-hints.tsx';
import { combineHeaders, getDomainUrl } from './utils/misc.tsx';
import { parseToast } from './utils/notifications.ts';
import { makeTimings } from './utils/timing.server.ts';

const disableNavbarOn = ['/login', '/dashboard', '/admin'];
const disableFooterOn = ['/dashboard', '/admin'];
const RemixDevTools =
process.env.NODE_ENV === 'development' ?
lazy(() => import('remix-development-tools')) :
null;

export const links: LinksFunction = () => {
  return [
  { rel: 'stylesheet', href: tailwindStylesheetUrl },
  { rel: 'stylesheet', href: tremorCss },
  { rel: 'stylesheet', href: picoStylesheetUrl },
  { rel: 'stylesheet', href: themeStylesheetUrl },
  // { rel: 'stylesheet', href: toastStylesheetUrl },
  {
    rel: 'apple-touch-icon',
    sizes: '180x180',
    href: '/apple-touch-icon.png'
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '32x32',
    href: '/favicon-32x32.png'
  },
  {
    rel: 'icon',
    type: 'image/png',
    sizes: '16x16',
    href: '/favicon-16x16.png'
  },
  { rel: 'shortcut icon', href: '/favicon.ico' },
  { rel: 'manifest', href: '/site.webmanifest' }];

};

export const meta: MetaFunction = () =>
process.env.NODE_ENV === 'production' ?
{
  charset: 'utf-8',
  title: 'KIZ Digital',
  viewport: 'width=device-width,initial-scale=1',
  'msapplication-TileColor': '#171717',
  'theme-color': '#ffffff'
} :
{
  charset: 'utf-8',
  title: 'KIZ Digital',
  viewport: 'width=device-width,initial-scale=1',
  robots: 'noindex,nofollow',
  'msapplication-TileColor': '#171717',
  'theme-color': '#ffffff'
};

export async function loader({ request }: LoaderArgs) {
  const timings = makeTimings('root loader');
  const [{ createToastSession }, { createPlatformSession }, user] =
  await Promise.all([
  getToastSession(request),
  getPlatformSession(request),
  getUser(request)]
  );

  const [
  { headers: toastHeaders, toastId },
  {
    headers: organizationHeaders,
    organizationId: platformId,
    organizationUserId: platformUserId
  }] =
  await Promise.all([createToastSession(), createPlatformSession()]);

  return jsonHash(
    {
      ...getPublicKeys(),
      requestInfo: {
        hints: getHints(request),
        origin: getDomainUrl(request),
        path: new URL(request.url).pathname,
        userPrefs: {}
      },
      user,
      toastId,
      platformId,
      platformUserId
    },
    {
      headers: combineHeaders(
        {
          'Server-Timing': timings.toString()
        },
        toastHeaders,
        organizationHeaders
      )
    }
  );
}

export type RootLoaderData = SerializeFrom<typeof loader>;

export const headers: HeadersFunction = ({ loaderHeaders }) => {
  const headers = {
    'server-timing': loaderHeaders.get('Server-Timing') ?? ''
  };
  return headers;
};

type DocumentProps = {
  children: React.ReactNode;
  title?: string;
  theme?: string;
  locale?: string;
};

export const Head = createHead(() =>
<>
		<Meta />
		<Links />
		{process.env.NODE_ENV === 'production' &&
  <>
				<script
      async
      src="https://www.googletagmanager.com/gtag/js?id=G-DF4YC0030H">
    </script>
				<script
      dangerouslySetInnerHTML={{
        __html: `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', 'G-DF4YC0030H');
`
      }}>
    </script>
			</>}

		<script
    defer
    src="https://analytics.kiz.co.zm/hrcknzi1zmi8cm9o36t91hb1"
    data-website-id={ENV.UMAMI_TRACKING_ID}>
  </script>
	</>
);

const Document = ({ children }: DocumentProps) => {
  const { pathname } = useLocation();
  const disableFooter = disableFooterOn.some((v) => startsWith(pathname, v));
  const disableNavbar = disableNavbarOn.some((v) => startsWith(pathname, v));

  return (
    <>
			<Head />
			{!disableNavbar && <Navbar />}
			<main className="tw-flex-1">
				{children}
				<FeedbackForm redirectTo={pathname} />
				{/* <DiscountCard redirectTo={pathname} /> */}
			</main>
			{!disableFooter && <Footer />}
			{/* <ToastContainer pauseOnHover theme="dark" position="bottom-right" /> */}
			<ScrollRestoration />
			<Scripts />
			<LiveReload />
		</>);

};

function App() {
  const { publicKeys, toastId } = useLoaderData<typeof loader>();
  const userToast = useEventSource(`/events/toasts/${toastId}`);
  const isHydrated = useHydrated();
  const toastTime = useRef<number>();

  useEffect(() => {
    if (isHydrated) {
      if (!userToast) return;

      const toastData = parseToast(userToast);
      // console.debug('toastData', toastData)
      if (toastData.createdAt !== toastTime.current) {
        toastTime.current = toastData.createdAt;
        const type = toastData.options?.type;

        const msg = toastData.message;

        switch (type) {
          case 'error':
            toast.error(msg, toastData.options);
            break;

          case 'success':
            toast.success(msg, toastData.options);
            break;

          default:
            toast(toastData.message, toastData.options);
            break;
        }
      }
    }
  }, [isHydrated, userToast]);

  return (
    <Document>
			<Outlet />
			<PublicEnv {...publicKeys} />
			{RemixDevTools ?
      <Suspense>
					<RemixDevTools />
				</Suspense> :
      null}
			<Toaster position="bottom-right" theme="dark" closeButton={true} />
		</Document>);

}

export default withSentry(App);

export function ErrorBoundary() {
  return (
    <Document>
			<main className="container">
				<GlobalErrorBoundary />
			</main>
		</Document>);

}

export function CatchBoundary() {
  const caught = useCatch();
  logger.warn({ caught }, 'Caught error:');

  let msg = '';
  if (caught.data) {
    if (caught.data.message) {
      msg = caught.data.message;
      if (caught.data.message.includes('getaddrinfo')) {
        msg = 'Please check your internet connection.';
      }
    } else {
      msg = caught.data;
    }
  }

  return (
    <Document>
			<main className="container tw-mx-auto tw-flex tw-w-full tw-max-w-7xl tw-flex-auto tw-flex-col tw-justify-center tw-px-6 tw-py-24 lg:tw-px-8">
				<p className="!tw-mb-2 tw-text-base tw-font-semibold tw-leading-8 tw-text-brand-primary">
					{caught.status}
				</p>
				<h1 className="!tw-mb-4 tw-mt-2 tw-text-3xl tw-font-bold tw-tracking-tight tw-text-gray-900 sm:tw-text-5xl">
					{' '}
					{caught.status === 404 ?
          'Page ' + caught.statusText.toLowerCase() :
          caught.data.title ?
          caught.data.title :
          caught.statusText}
				</h1>
				<p className="tw-text-base tw-leading-7 tw-text-gray-600">{msg}</p>
				<div className="tw-mt-10">
					<Link
            to="/"
            className="tw-text-sm tw-font-semibold tw-leading-7 tw-text-brand-primary">

						<span aria-hidden="true">&larr;</span> Back to home
					</Link>
				</div>
			</main>
		</Document>);

}

export const handle: RouteHandle = {
  id: 'root'
};