import { Popover, Transition } from '@headlessui/react'
import { NavLink } from '@remix-run/react'
import { Fragment } from 'react'

const services = [
	{ name: 'Buy Road Tax', href: 'https://eservices.gov.zm/#/service/4' },
]

export default function NavbarServices() {
	return (
		<Popover className="tw-relative">
			<Popover.Button className="border-0 tw-mb-0 tw-inline-flex tw-items-center tw-gap-x-1 tw-border-0  !tw-px-2 !tw-py-1 !tw-font-semibold tw-leading-6 tw-tracking-wide tw-text-brand-primary hover:tw-bg-amber-500 hover:tw-text-black sm:!tw-text-lg">
				<span>Services</span>
			</Popover.Button>

			<Transition
				as={Fragment}
				enter="tw-transition tw-ease-out tw-duration-200"
				enterFrom="tw-opacity-0 tw-translate-y-1"
				enterTo="tw-opacity-100 tw-translate-y-0"
				leave="tw-transition tw-ease-in tw-duration-150"
				leaveFrom="tw-opacity-100 tw-translate-y-0"
				leaveTo="tw-opacity-0 tw-translate-y-1"
			>
				<Popover.Panel className="tw-absolute tw-right-5 tw-z-10 tw-mx-auto tw-mt-5 tw-flex tw-w-screen tw-max-w-min tw-px-4">
					<div className="tw-w-48 tw-shrink tw-rounded-xl tw-bg-white tw-p-4 tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900 tw-shadow-lg tw-ring-1 tw-ring-gray-900/5">
						{services.map(item => (
							<NavLink
								key={item.name}
								to={item.href}
								className="decorate-none tw-block tw-p-2 tw-text-black hover:tw-text-brand-primary"
							>
								{item.name}
							</NavLink>
						))}
					</div>
				</Popover.Panel>
			</Transition>
		</Popover>
	)
}
