import { Popover, Transition } from '@headlessui/react'
import { NavLink } from '@remix-run/react'
import { Fragment } from 'react'

const options = [
	{ name: 'Dashboard', href: '/dashboard' },
	{ name: 'Profile', href: '/dashboard?tab=profile' },
	{ name: 'Log out', href: '/logout' },
]

export default function UserOptions({ initials, closePopover }) {
	// console.log('Initials: ', initials)
	return (
		<Popover className="tw-relative">
			{/* <Popover.Button className="border-0 tw-mb-0 tw-inline-flex tw-justify-end tw-items-right tw-gap-x-1 tw-border-0  !tw-px-1 !tw-py-1 !tw-font-semibold tw-leading-6 tw-tracking-wide tw-text-brand-primary hover:tw-text-black sm:!tw-text-lg">
        <span
          className="tw-align-center tw-inline-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-full"
          style={{ backgroundColor: '#f59e0b' }}
        >
          <span className="tw-text-white tw-font-medium tw-leading-none hover:tw-text-black">
            {initials}
          </span>
        </span>
      </Popover.Button> */}
			<Popover.Button className="border-0 tw-mb-0 tw-inline-flex tw-items-center tw-gap-x-1 tw-border-0  !tw-px-2 !tw-py-1 !tw-font-semibold tw-leading-6 tw-tracking-wide tw-text-brand-primary hover:tw-bg-amber-500 hover:tw-text-black sm:!tw-text-lg">
				<span>{initials}</span>
			</Popover.Button>

			<Transition
				as={Fragment}
				enter="tw-transition tw-ease-out tw-duration-200"
				enterFrom="tw-opacity-0 tw-translate-y-1"
				enterTo="tw-opacity-100 tw-translate-y-0"
				leave="tw-transition tw-ease-in tw-duration-150"
				leaveFrom="tw-opacity-100 tw-translate-y-0"
				leaveTo="tw-opacity-0 tw-translate-y-1"
			>
				<Popover.Panel className="tw-absolute tw-right-5 tw-z-10 tw-mx-auto tw-mt-5 tw-flex tw-w-screen tw-max-w-min tw-px-4">
					<div className="tw-w-48 tw-shrink tw-rounded-xl tw-bg-white tw-p-4 tw-text-sm tw-font-semibold tw-leading-6 tw-text-gray-900 tw-shadow-lg tw-ring-1 tw-ring-gray-900/5">
						{options.map(item => (
							<NavLink
								key={item.name}
								to={item.href}
								onClick={() => closePopover()}
								className="decorate-none tw-block tw-p-2 tw-text-black hover:tw-text-brand-primary"
							>
								{item.name}
							</NavLink>
						))}
					</div>
				</Popover.Panel>
			</Transition>
		</Popover>
	)
}
