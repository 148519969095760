import { Popover } from '@headlessui/react'
import {
	Bars3Icon,
	XMarkIcon,
	UserCircleIcon,
} from '@heroicons/react/24/outline'
import { NavLink, useRouteLoaderData } from '@remix-run/react'
import { useRef } from 'react'
import logo from '~/assets/img/kiz-logo.svg'
import { type RootLoaderData } from '~/root.tsx'
import NavbarServices from './navbar-services.tsx'
import UserOptions from './user-options.tsx'

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(' ')
}
export interface EmailProp {
	split: any
	email: string
	component: string
}

function getEmailInitials(email: EmailProp) {
	// Step 1: Extract the part before the "@" symbol
	const namePart = email.split('@')[0]
	// Step 2: Split the name part into components if it contains a period
	const nameComponents = namePart.split('.')
	// Step 3: Take the first letter of each component
	const initials = nameComponents
		.map((component: string) => component.charAt(0).toUpperCase())
		.join('')
	// Step 4: Return the combined initials
	return initials
}

export const Navbar = () => {
	const data = useRouteLoaderData('root') as RootLoaderData
	const isAuthenticated = data?.user ? true : false
	const buttonRef = useRef<HTMLButtonElement>(null)

	// let initials = ''

	// useEffect(() => {
	//   if (isAuthenticated && initials === '') {
	//     const name = data?.user?.firstName + ' ' + data?.user?.lastName || ''
	//     const rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu')
	//     let rawInitials = [...name.matchAll(rgx)] || []
	//     initials = (
	//       (rawInitials.shift()?.[1] || '') + (rawInitials.pop()?.[1] || '')
	//     ).toUpperCase()
	//   }
	// }, [data, initials])

	const closePopover = () => {
		// console.log('Close Popover')
		buttonRef.current?.click()
	}

	return (
		<Popover
			as="header"
			className={({ open }) =>
				classNames(
					open ? 'tw-fixed tw-inset-0 tw-z-40 tw-h-96 tw-overflow-y-auto' : '',
					'tw-bg-white tw-shadow-sm lg:tw-static lg:tw-overflow-y-visible',
				)
			}
		>
			{({ open, close }) => (
				<>
					<div className="tw-mx-auto tw-max-w-7xl tw-bg-white tw-px-4 sm:tw-px-6 lg:tw-px-8">
						<div className="tw-relative tw-flex tw-justify-between lg:tw-gap-8 xl:tw-grid xl:tw-grid-cols-3">
							<div className="tw-flex md:tw-absolute md:tw-inset-y-0 md:tw-left-0 lg:tw-static xl:tw-col-span-2">
								<div className="tw-flex tw-flex-shrink-0 tw-items-center hover:tw-animate-pulse">
									<NavLink to="/">
										<img className="tw-h-16" src={logo} alt="KIZ logo" />
									</NavLink>
								</div>
							</div>
							<div className="tw-flex tw-items-center md:tw-absolute md:tw-inset-y-0 md:tw-right-0 lg:tw-hidden">
								{/* Mobile menu button */}
								<Popover.Button
									ref={buttonRef}
									className="tw--mx-2 tw-inline-flex tw-items-center tw-justify-center tw-rounded-md tw-p-2 tw-text-gray-400 hover:tw-bg-gray-100 hover:tw-text-gray-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-indigo-500"
								>
									<span className="tw-sr-only">Open menu</span>
									{open ? (
										<XMarkIcon className="tw-block tw-w-8" aria-hidden="true" />
									) : (
										<Bars3Icon className="tw-block tw-w-8" aria-hidden="true" />
									)}
								</Popover.Button>
							</div>
							<div className="tw-hidden tw-justify-end lg:tw-flex lg:tw-items-center">
								<NavLink
									to="/insure"
									className=" decorate-none tw-m-0 !tw-px-2 !tw-py-1 !tw-font-semibold tw-tracking-wide hover:tw-bg-amber-500  hover:tw-text-black sm:!tw-text-lg"
									onClick={() => close()}
								>
									Insure
								</NavLink>
								<a
									href="https://eservices.gov.zm/#/service/4"
									target="_blank"
									rel="noreferrer"
									className=" decorate-none tw-m-0 !tw-px-2 !tw-py-1 !tw-font-semibold tw-tracking-wide hover:tw-bg-amber-500  hover:tw-text-black sm:!tw-text-lg"
									onClick={() => close()}
								>
									Buy Road Tax
								</a>
								{/* <NavbarServices /> 'https://eservices.gov.zm/#/service/4' */}
								{isAuthenticated ? (
									<span className="inline-flex items-center justify-center size-[46px] rounded-full bg-yellow-500 font-semibold text-white leading-none">
										<UserOptions
											initials={getEmailInitials(data?.user?.email)}
											closePopover={close}
										/>
									</span>
								) : (
									<NavLink
										to="/login"
										className=" decorate-none tw-m-0 !tw-px-2 !tw-py-1 !tw-font-semibold tw-tracking-wide hover:tw-bg-amber-500  hover:tw-text-black sm:!tw-text-lg"
									>
										Login
									</NavLink>
								)}
							</div>
						</div>
					</div>

					<Popover.Panel
						as="nav"
						className="tw-justify-end lg:tw-hidden"
						aria-label="Global"
					>
						{/* tw-px-4 */}
						<div className="tw-max-w-fill tw-space-y-1 tw-bg-white tw-px-2 tw-pb-3 tw-pt-2 tw-text-right sm:tw-px-4">
							<NavLink
								to="/insure"
								className=" decorate-none tw-m-0 !tw-px-2 !tw-py-1 !tw-font-semibold tw-tracking-wide hover:tw-bg-amber-500  hover:tw-text-black sm:!tw-text-lg"
								onClick={() => closePopover()}
							>
								Insure
							</NavLink>
							<NavbarServices />
							{isAuthenticated ? (
								<NavLink
									to="/login"
									className=" decorate-none tw-m-0 !tw-px-2 !tw-py-1 !tw-font-semibold tw-tracking-wide hover:tw-bg-amber-500  hover:tw-text-black sm:!tw-text-lg"
								>
									Login
								</NavLink>
							) : (
								<NavLink
									to="/login"
									className=" decorate-none tw-m-0 tw-rounded-full !tw-px-1 !tw-py-1 !tw-font-semibold tw-tracking-wide hover:tw-bg-amber-500  hover:tw-text-black sm:!tw-text-lg"
								>
									<UserCircleIcon
										className="tw-block tw-w-8 hover:tw-text-black"
										aria-hidden="true"
									/>
								</NavLink>
							)}
						</div>
					</Popover.Panel>
				</>
			)}
		</Popover>
	)
}
