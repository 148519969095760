import { Dialog, Transition } from '@headlessui/react'
import { InlineIcon } from '@iconify/react'
import { Fragment, useEffect, useState } from 'react'
import { feedbackFormSchema } from '~/routes/api+/send-feedback/index.ts'
import { Form } from '../forms/form.tsx'

type Props = { redirectTo: string }

export default function FeedbackForm({ redirectTo }: Props) {
	const [show, setShow] = useState(false)
	const [redirect, setRedirect] = useState('')

	useEffect(() => {
		if (redirect !== redirectTo) {
			setTimeout(() => {
				setRedirect(redirectTo)
			}, 3000)
		}
	}, [redirect, redirectTo])

	return (
		<>
			<Transition.Root show={show} as={Fragment}>
				<Dialog
					as="div"
					className="tw-relative tw-z-10"
					onClose={() => setShow(!show)}
				>
					<Transition.Child
						as={Fragment}
						enter="tw-ease-out tw-duration-300"
						enterFrom="tw-opacity-0"
						enterTo="tw-opacity-100"
						leave="tw-ease-in tw-duration-200"
						leaveFrom="tw-opacity-100"
						leaveTo="tw-opacity-0"
					>
						<div className="tw-fixed tw-inset-0 tw-bg-gray-500 tw-bg-opacity-75 tw-transition-opacity" />
					</Transition.Child>

					<Transition.Child
						as={Fragment}
						enter="tw-ease-out tw-duration-300"
						enterFrom="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
						enterTo="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
						leave="tw-ease-in tw-duration-200"
						leaveFrom="tw-opacity-100 tw-translate-y-0 sm:tw-scale-100"
						leaveTo="tw-opacity-0 tw-translate-y-4 sm:tw-translate-y-0 sm:tw-scale-95"
					>
						<Dialog.Panel
							as="div"
							className="tw-fixed tw-bottom-0 tw-right-0 tw-w-full tw-max-w-sm tw-overflow-hidden tw-rounded-md tw-bg-white tw-px-4 tw-py-2 tw-shadow-sm sm:tw-bottom-10 sm:tw-right-10"
						>
							<div className="tw-mt-3 sm:tw-mt-5">
								<Dialog.Title
									as="h3"
									className="tw-mb-2 tw-text-base tw-font-semibold tw-leading-6 tw-text-gray-900"
								>
									Submit feedback
								</Dialog.Title>
							</div>
							<Form
								className="tw-m-0"
								action="/api/send-feedback"
								method="post"
								schema={feedbackFormSchema}
								multiline={['message']}
								values={{
									redirectTo,
								}}
							>
								{({
									Field,
									Errors,
									Button,
									formState,
									getValues,
									register,
									reset,
								}) => (
									<>
										<Field name="redirectTo" hidden />
										<Field name="name" />
										{/* <Field name="lastName" /> */}
										<Field name="email" />
										{/* <Field name="phone">
                      {({ Errors, Label }) => (
                        <>
                          <div className="tw-mb-4">
                            <Label />
                            <div className="tw-mt-2 tw-flex tw-rounded-md tw-shadow-sm">
                              <span className="tw-inline-flex tw-items-center tw-rounded-l-md tw-border tw-border-r-0 tw-border-gray-300 tw-px-3 tw-text-gray-500 sm:tw-text-sm">
                                +260
                              </span>
                              <input
                                className="!tw-mb-0 tw-block tw-w-full tw-min-w-0 tw-flex-1 tw-rounded-none tw-rounded-r-md tw-border-0 tw-bg-white tw-py-1.5 tw-text-gray-900 tw-ring-1 tw-ring-inset tw-ring-gray-300 placeholder:tw-text-gray-400 focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-brand-primary sm:tw-text-sm sm:tw-leading-6"
                                {...register('phone')}
                              />
                            </div>
                            <Errors />
                          </div>
                        </>
                      )}
                    </Field> */}
										<Field name="message" />
										<section className="grid">
											<button
												className="secondary outline"
												onClick={() => {
													setShow(false)
													reset()
												}}
											>
												Cancel
											</button>
											<Button
												aria-busy={formState.isSubmitting}
												disabled={formState.isSubmitting}
												onClick={() => {
													if (formState.isValid) {
														setTimeout(() => {
															reset()
															setShow(false)
														}, 500)
													}
												}}
											>
												{formState.isSubmitting
													? 'Sending feedback...'
													: 'Send feedback'}
											</Button>
										</section>
										<Errors />
									</>
								)}
							</Form>
						</Dialog.Panel>
					</Transition.Child>
				</Dialog>
			</Transition.Root>

			{redirect === redirectTo ? (
				<button
					className="fade-in sm:bottom-30 tw-fixed tw-bottom-20 tw-right-5 tw-m-0 tw-flex tw-h-10 tw-w-24 tw-items-center tw-justify-center tw-rounded-full tw-bg-black tw-text-sm tw-text-white tw-shadow-md hover:tw-bg-amber-400 sm:tw-right-10"
					onClick={() => setShow(!show)}
				>
					<span className="tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center !tw-rounded-full tw-border-none tw-bg-transparent !tw-text-white">
						<span className="tw-block tw-h-5 tw-w-5">
							<InlineIcon
								icon="uil:feedback"
								className="tw-h-5 tw-w-5 tw-pr-1"
								aria-hidden="true"
							/>
						</span>
						Feedback
					</span>
				</button>
			) : null}
		</>
	)
}
