import fs from 'fs'
import path from 'path'
import { fileURLToPath } from 'url'
import { type ActionArgs } from '@remix-run/node'
import { redirect } from '@remix-run/node'
import { makeDomainFunction } from 'domain-functions'
import { startCase } from 'lodash-es'
import { performMutation } from 'remix-forms'
import { badRequest } from 'remix-utils'
import { z } from 'zod'
import { sendMail } from '#app/utils/mail.server.ts'
import { environment } from '~/utils/environment.server.ts'

export const feedbackFormSchema = z.object({
	name: z
		.string({
			invalid_type_error: 'Please enter your name',
			required_error: 'Your name is required',
		})
		.trim()
		.min(2, { message: 'Your name is too short' })
		.transform(s => {
			const [n, ...rest] = s.split('')
			return startCase(n) + rest.join('')
		}),
	// lastName: z
	//   .string({
	//     invalid_type_error: 'Please enter your last name',
	//     required_error: 'Your last name is required',
	//   })
	//   .trim()
	//   .min(2, { message: 'Your name is too short' })
	//   .transform((s) => {
	//     const [n, ...rest] = s.split('')
	//     return startCase(n) + rest.join('')
	//   }).optional(),
	email: z
		.string({
			invalid_type_error: 'Please enter a valid email address',
			required_error: 'Your email address is required',
		})
		.email({ message: 'Invalid email address' })
		.trim()
		.transform(e => e.toLowerCase()),
	// phone: z
	//   .preprocess(
	//     (val) => String(val).replace(/[\D]+/g, ''),
	//     z
	//       .string()
	//       .regex(
	//         /(^[7,9]\d{8}$)/,
	//         'Please enter a valid Zambian mobile number without the country code (260)',
	//       ),
	//   )
	//   .optional(),
	message: z
		.string({
			invalid_type_error: 'Please enter a message',
			required_error: 'Please enter a message',
		})
		.trim()
		.min(10, { message: 'Your message is too short' }),
	redirectTo: z.string(),
})

const mutation = makeDomainFunction(feedbackFormSchema)(async values => {
	const dirname = path.dirname(fileURLToPath(import.meta.url))

	const pathToEmail = path.join(
		dirname,
		`../app/assets/emails/customer_feedback.html`,
	)
	let emailFile = fs.readFileSync(pathToEmail).toString('utf-8')
	emailFile = emailFile.replace(/\[message\]/, values.message)
	emailFile = emailFile.replace(/\[name\]/, values.name)
	emailFile = emailFile.replace(/\[email\]/g, values.email)

	const pathToClientEmail = path.join(
		dirname,
		'../app/assets/emails/customer_feedback_client.html',
	)
	let clientEmail = fs.readFileSync(pathToClientEmail).toString('utf-8')
	clientEmail = clientEmail.replace(/\[Name\]/, values.name)
	clientEmail = clientEmail.replace(/\[message\]/, values.message)

	const { MAIL_FROM: mailFrom, MAIL_FROM_NAME: mailFromName } = environment()

	sendMail({
		to: [{ Email: 'customerservice@kiz.co.zm', Name: 'KIZ Customer Service' }],
		bcc: [
			{ Email: 'papias.banda@kuala.io', Name: 'Papias Banda' },
			{ Email: 'chanda.mulenga@kuala.io', Name: 'Chanda Mulenga' },
		],
		replyTo: {
			Email: values.email,
			Name: values.name,
		},
		subject: 'KIZ Digital Feedback',
		from: { Email: mailFrom, Name: mailFromName },
		html: emailFile,
	}).catch(error => console.error(error))

	sendMail({
		to: [{ Email: values.email, Name: values.name }],
		bcc: [
			{ Email: 'chanda.mulenga@kuala.io', Name: 'Chanda Mulenga' },
			{ Email: 'papias.banda@kuala.io', Name: 'Papias Banda' },
		],
		replyTo: {
			Email: 'customerservice@kiz.co.zm',
			Name: 'KIZ Customer Service',
		},
		subject: 'Thank you for your feedback',
		from: { Email: mailFrom, Name: mailFromName },
		html: clientEmail,
	}).catch(error => console.error(error))

	return { redirectTo: values.redirectTo }
})

export const action = async ({ request }: ActionArgs) => {
	const result = await performMutation({
		request,
		schema: feedbackFormSchema,
		mutation,
	})

	if (result.success) {
		return redirect(result.data.redirectTo)
	}

	return badRequest({ message: 'Something went wrong.' })
}
