// import { createId as cuid } from '@paralleldrive/cuid2'
// import { type ToastOptions } from 'react-toastify'
import { type ExternalToast } from 'sonner'

export type ToastData = {
	id: string
	message: string
	description?: string
	createdAt: number
	type?: 'default' | 'success' | 'error'
}

// type ToasterOptions = Parameters<typeof toast>[1]
type ToastTypes =
	| 'normal'
	| 'action'
	| 'success'
	| 'info'
	| 'warning'
	| 'error'
	| 'loading'

export type ToastNotification = {
	message: string
	createdAt: number
	options?: ExternalToast & {
		closeButton?: boolean
		type?: ToastTypes
	}
	// options?: {
	//   description?: string
	//   type?: 'default' | 'success' | 'error'
	//   duration?: number
	//   position?:
	//   dismissible?: boolean
	//   invert?:
	// }
}

export function createToast({
	message,
	options,
}: {
	message: string
	options?: ToastNotification['options']
}) {
	return JSON.stringify({
		message,
		createdAt: Date.now(),
		options,
	})
}

export function parseToast(toast: string) {
	return JSON.parse(toast) as ToastNotification
}

export function parseNotification(notification: string) {
	return JSON.parse(notification) as ToastData
}
